import React from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"

import ogIcon from "./../images/favicon.jpg"

import Layout from "../components/layout"
import SimpleSlider from "../components/Slider"
import CallNow from "../components/callNow"
import ImageQuery from "../components/imageQuery"
import GoogleMap from "../components/googleMap"

const IndexPage = () => {
  return (
    <Layout>
      <Helmet>
        <title>Wulkanizacja | Serwis i Wymiana Opon Tychy - KostaAuto</title>

        <link rel="canonical" href="https://oponytychy.pl" />
        <meta
          name="google-site-verification"
          content="3MDuj-a0juLudORlB5PRnUppK0RhnXyNo5g0gVYGitE"
        />
        <meta
          name="google-site-verification"
          content="vME04-XNUJkuX152dIy7PNF3qxGM10Zj4KM9TYwBxFk"
        />
        <meta
          name="description"
          content="Wulkanizacja Tychy KostaAuto - WYMIANA OPON w Tychach. Opony letnie i opony zimowe do twojego samochodu. Wulkanizacja opon na zimno i goraco. WYMIENIAMY OPONY z letnich na zimowe oraz z zimowych na letnie na nowoczesnych maszynach. Sprzedajemy opony letnie, opony zimowe oraz opony wielosezonowe"
        />
        <meta
          name="keywords"
          content="opony Tychy, wulkanizacja, serwis ogumienia, wymiana opon, wymiana opon Tychy, naprawa opon Tychy, opony letnie Tychy, opony zimowe Tychy, wymiana opon wielosezonowych,  "
        />

        <meta property="og:title" content="Tychy Wulkanizacja KostaAuto" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://oponytychy.pl" />
        <meta property="og:image" content={`https://oponytychy.pl${ogIcon}`} />
        <script type="application/ld+json">
          {`
                    {
                        "@context": "http://www.schema.org",
                        "@type": "AutoRepair",
                        "name": "Kosta Auto",
                        "url": "https://oponytychy.pl",
                        "priceRange": "$$",
                        "image": "https://oponytychy.pl${ogIcon}",
                        "description": "Looking for a tire change in Tychy? We offer tyre replacements & flat tyre repairs. \nTurn to our service to save your valuable time and money.",
                        "address": {
                            "@type": "PostalAddress",
                            "streetAddress": "Myśliwska 140",
                            "addressLocality": "Tychy",
                            "addressRegion": "Silesia",
                            "postalCode": "43-100",
                            "addressCountry": "Poland"
                        },
                        "geo": {
                            "@type": "GeoCoordinates",
                            "latitude": "50.1095657793638N",
                            "longitude": "18.9572390689332E"
                        },
                        "hasMap": "https://g.page/wymiana-opon-tychy?share",
                    }
                `}
        </script>
      </Helmet>

      <div className="main">
        <div className="container_12">
          <div className="grid_12">
            <SimpleSlider />
          </div>
        </div>
        <CallNow />
        <section id="content">
          <div className="container_12">
            <div>
              <div className="grid_4">
                <div className="box1 indents1">
                  <h2 className="p3-1">
                    <span>
                      Nasza <strong>Oferta</strong>
                    </span>
                  </h2>
                  <h3 className="p2">
                    Zapewniamy <strong>ekspresową wymianę opon</strong> na
                    <strong> felgach stalowych i aluminiowych</strong> z
                    wykorzystaniem najnowocześniejszych maszyn światowej klasy.{" "}
                    <strong>Koła wyważamy komputerowo</strong>. Dbając o
                    bezpieczeństwo naszych klient&oacute;w wszystkie śruby
                    k&oacute;ł dokręcamy kluczem dynamometrycznym.
                  </h3>
                  <p>
                    <strong>Wulkanizujemy opony</strong> w samochodach osobowych
                    i dostawczych w technologii na zimno i na gorąco. Zapewniamy
                    sezonowe
                    <strong> przechowywanie opon letnich</strong> i{" "}
                    <strong>opon zimowych</strong>. Oferujemy{" "}
                    <strong>opony letnie</strong> i
                    <strong> opony zimowe </strong>w konkurencyjnych cenach.
                  </p>
                  <Link
                    to="/oferta"
                    className="button"
                    aria-label="link do oferty wulkanizacji"
                  >
                    Czytaj więcej
                  </Link>
                </div>
              </div>
              <div className="grid_4">
                <div className="box1 indents1">
                  <h2 className="p3-1">
                    <span>
                      Godziny <strong>Otwarcia</strong>
                    </span>
                  </h2>
                  <h3 className="p2">
                    Chcesz zrobić <strong>wymianę opon</strong> po pracy?
                    Doskonale! U Nas jesteś zawsze mile widziany! Od
                    poniedziałku do piątku pracujemy w godzinach popołudniowych
                    a w soboty od samego rana. W niedzielę serwis jest
                    zamknięty.
                  </h3>
                  <Link
                    to="/kontakt"
                    className="button"
                    aria-label="przejdź do formularza kontaktowego"
                  >
                    Czytaj więcej
                  </Link>
                </div>
              </div>
              <div className="grid_4 last-col">
                <div className="box1 indents1">
                  <h2 className="p3-1">
                    <span>
                      Opony <strong>Felgi</strong>
                    </span>
                  </h2>
                  <h3 className="p2">
                    Oferujemy szeroki wyb&oacute;r{" "}
                    <strong>opon letnich </strong>i{" "}
                    <strong> opon zimowych</strong> oraz{" "}
                    <strong>felg stalowych</strong> i
                    <strong> felg aluminiowych</strong> wiodących światowych
                    producent&oacute;w. Oferowane przez nas produkty gwarantują
                    wysoką jakość i bezpieczeństwo użytkowania.
                  </h3>
                  <p>
                    Na zam&oacute;wienie klienta jesteśmy wstanie sprowadzić{" "}
                    <strong>opony</strong>, kt&oacute;re nie są w naszej stałej
                    ofercie.
                    <strong>Dobierzemy opony</strong> dostosowane do Panstwa
                    budżetu i wymagań technicznych samochodu.
                  </p>
                  <Link
                    to="/opony-tychy"
                    className="button"
                    aria-label="dowiedz sie więcej na temat sprzedawanych opon"
                  >
                    Czytaj więcej
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="container_12">
            <div>
              <div className="grid_4">
                <div className="box1 indents1">
                  <h2 className="p3-1">
                    <span>
                      Nowe <strong>Opony&nbsp;&nbsp;&nbsp;</strong>
                    </span>
                  </h2>
                  <Link
                    to="/opony-tychy"
                    aria-label="wybierz opony które chcesz zamówić"
                  >
                    <ImageQuery imgName="nowe" alt="Opony Tychy - nowe" />
                  </Link>
                </div>
              </div>
              <div className="grid_4">
                <div className="box1 indents1">
                  <h2 className="p3-1">
                    <span>
                      Wymiana <strong>Opon</strong>
                    </span>
                  </h2>
                  <Link
                    to="/kontakt"
                    aria-label="zadzwoń i umów się na wymianę opon"
                  >
                    <ImageQuery alt="Wymiana opon Tychy" imgName="wymiana" />
                  </Link>
                </div>
              </div>
              <div className="grid_4 last-col">
                <div className="box1 indents1">
                  <h2 className="p3-1">
                    <span>
                      Naprawa <strong>Opon</strong>
                    </span>
                  </h2>
                  <Link
                    to="/oferta"
                    aria-label="dowiedz się co jeszcze robimy oprócz łatania dziur w oponach i dętkach"
                  >
                    <ImageQuery imgName="naprawa" alt="Serwis opon Tychy" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <div className="container_12">
          <div className="wrapper">
            <div className="grid_4">
              <div className="box1 indents1">
                <h2 className="p3-1">
                  <strong>O Nas</strong>
                </h2>
                <h3 className="p4">
                  Firma Kosta Auto powstała w lipcu 1992 roku. Od początku
                  naszej działalności była firmą rodzinną. Wiele lat
                  doświadczenia w branży oponiarskiej jest teraz do państwa
                  dyspozycji.{" "}
                </h3>
                <p className="p4">
                  Dzięki wsp&oacute;łpracy z hurtowniami oponiarskimi jesteśmy
                  wstanie zaproponować Państwu szeroki wachlarz{" "}
                  <strong>opon letnich</strong> i<strong>opon zimowych</strong>{" "}
                  uznanych światowych producent&oacute;w. Jeśli jakaś{" "}
                  <strong>opona</strong> nie jest dostępna z naszego magazynu
                  jesteśmy wstanie ją sprowadzić w bardzo kr&oacute;tkim czasie.
                </p>

                <p className="color-2 p4">
                  Nasz personel został przeszkolony w
                  <strong> wulkanizacji opon</strong> i{" "}
                  <strong>wulkanizacji dętek </strong>
                  samochod&oacute;w osobowych i dostawczych metodami na gorąco i
                  zimno materiałami renomowanej firmy TIP TOP GmbH.
                </p>
              </div>
            </div>
            <div className="grid_4">
              <div className="box1 indents1">
                <h2 className="p3-1">
                  <span>Oferta</span>
                </h2>
                <ol className="list-1">
                  <li>
                    <strong>Opony letnie</strong>
                  </li>
                  <li>
                    <strong>Opony zimowe</strong>
                  </li>
                  <li>
                    <strong>Wulkanizacja opon </strong>na zimno
                  </li>
                  <li>
                    <strong>Wulkanizacja opon</strong> na gorąco
                  </li>
                  <li>
                    <strong>Wymiana opon</strong>
                  </li>
                  <li>
                    <strong>Sprzedaż opon</strong>
                  </li>
                  <li>
                    <strong>Sprzedaż felg</strong>
                  </li>
                  <li>
                    <strong>Komputerowe wyważanie k&oacute;ł</strong>
                  </li>
                  <li>
                    <strong>Sezonowe przechowywanie opon</strong>
                  </li>
                  <li className="last-item">
                    <strong>Naprawa dętek</strong>
                  </li>
                </ol>
              </div>
            </div>
            <div className="grid_4">
              <div className="box1 indents1">
                <h2 className="p3-1">
                  <span>Wulkanizacja </span>
                  <strong>gdzie się znajduje?</strong>
                </h2>
                <h3 className="p4">
                  Kieruj się w stronę stacji kolejowej TYCHY - ŻWAKÓW. Po prawej
                  stronie ulicy Myśliwskiej, 300m przed przejazdem kolejowym
                  znajduje się siedziba naszej firmy.
                </h3>
                <div className="vcard">
                  <span className="fn n">
                    <span className="given-name"></span>
                    <span className="additional-name"></span>
                    <span className="family-name"></span>
                  </span>
                  <div className="color-2">Kosta Auto</div>
                  <div className="adr">
                    <div className="color-2">Myśliwska 140</div>
                    <span className="color-2"> Tychy, 43-100</span>
                  </div>

                  <div className="clear">
                    wulkanizacja, wymiana opon, sprzedaż opon, serwis opon
                  </div>
                </div>

                <p className="color-2">&nbsp;</p>

                <div className="vcard"> </div>

                <p className="color-2">Godziny otwarcia:</p>
                <div class="color-2">Poniedziałek - Piątek 16 - 20 </div>
                <div class="color-2">Sobota 8 - 12 </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <GoogleMap />
    </Layout>
  )
}

export default IndexPage
