import React from "react";
import {graphql, StaticQuery} from "gatsby"
import Img from "gatsby-image"

export const portraitImage=graphql`
  fragment portraitImage on File {
    childImageSharp {
      fluid(maxWidth: 288, maxHeight: 433) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`
export const sliderImage=graphql`
  fragment sliderImage on File {
    childImageSharp {
        fluid(maxWidth: 940, maxHeight: 423, quality: 100) {
          ...GatsbyImageSharpFluid
        }
    }
  }
`
export const notFoundImage=graphql`
  fragment notFoundImage on File {
    childImageSharp {
      fluid(maxWidth: 940, maxHeight: 403) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

const ImagePage=(props) => {
  const {imgName, alt}=props

  return (
    <StaticQuery
      query={graphql`
            query {
                naprawa: file(relativePath: { eq: "naprawa.png" }) {
                    ...portraitImage
                  }
                  nowe: file(relativePath: { eq: "nowe.png" }) {
                    ...portraitImage
                  }
                  wymiana: file(relativePath: { eq: "wymiana.png" }) {
                    ...portraitImage
                  }

                  wulkanizacjaTychy: file(relativePath: { eq: "wulkanizacja-tychy.jpg" }) {
                    ...sliderImage
                  }
                  oponyTychy: file(relativePath: { eq: "opony-tychy.jpg" }) {
                    ...sliderImage
                  }
                  wulkanizatorTychy: file(relativePath: { eq: "page1_slide3.jpg" }) {
                    ...sliderImage
                  }
                  wymianaOponTychy: file(relativePath: { eq: "wymiana-opon-tychy.jpg" }) {
                    ...sliderImage
                  }

                  notFoundImage: file(relativePath: { eq: "404.png" }) {
                    ...notFoundImage
                  }
               }
  `}

      render={data => {
        return (
          <Img
            fluid={data[imgName].childImageSharp.fluid}
            alt={alt}
          />
        )
      }}
    />
  )
}

export default ImagePage
