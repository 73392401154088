import React from 'react';

const GoogleMap=() => {
    return (
        <div className="main">
            <div className="container_12">
                <div className="map">
                    <iframe title="Wulkanizacja na mapie"
                        src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d5117.4711096832325!2d18.965854931213364!3d50.10995655528764!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x466f16ccedddb46f!2sKosta+Auto+-+Opony%2C+Wulkanizacja%2C+Wymiana+Opon!5e0!3m2!1spl!2spl!4v1451846322631"
                        width="930" height="350"></iframe>
                </div>
            </div>
        </div>
    )
}

export default GoogleMap
