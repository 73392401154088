import React from "react"
import callImage from "./../images/cta.png"
import "./callNow.scss"

const CallNow = () => {
  return (
    <div className="main">
      <div className="container_12">
        <div className="grid_12">
          <div className="tel_start">
            <a href="tel:+48511435200" aria-label="zadzwoń do nas">
              <img
                className="img"
                src={callImage}
                loading="lazy"
                alt="Call to action"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CallNow
