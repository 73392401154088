import React, {useEffect} from 'react';
import ImageQuery from './imageQuery'
import './slider.scss'

const SimpleSlider=() => {

    useEffect(() => {
        const stage=document.getElementById("stage");
        const stage2=document.getElementById("stage2");
        const fadeComplete=function(e) {stage.appendChild(arr[0]);};
        const fadeComplete2=function(e) {stage2.appendChild(arr2[0]);};

        const arr=stage.getElementsByClassName("fadeContainer");
        const arr2=stage2.getElementsByClassName("caption");
        const pagination=document.getElementById("pagination");
        const paginationEl=pagination.children;

        const addClass=() => {
            let counter=0

            setTimeout(() => {

                counter++
                let el=counter%4;
                let elPrev=(counter-1)%4
                paginationEl.item(el).classList.add("current");
                paginationEl.item(elPrev).classList.remove("current");

                setInterval(() => {
                    counter++
                    let el=counter%4;
                    let elPrev=(counter-1)%4
                    paginationEl.item(el).classList.add("current");
                    paginationEl.item(elPrev).classList.remove("current");

                }, 7000);
            }, 6000)
        }

        addClass();
        for (let i=0; i<arr.length; i++) {
            arr[i].addEventListener("animationend", fadeComplete, false);
            arr[i].addEventListener("animationstart", fadeComplete2, false);
            arr[i].addEventListener("animationend", addClass(), false);
        }
    }, []);

    return (
        <div className="slider">
            <div id="stage" className="slides_container">

                <div className='fadeContainer'>
                    <ImageQuery
                        alt="Wulkanizacja Tychy"
                        imgName="wulkanizacjaTychy"
                    />
                </div>

                <div className='fadeContainer'>
                    <ImageQuery
                        alt="Opony Tychy"
                        imgName="oponyTychy"
                    />
                </div>

                <div className='fadeContainer'>
                    <ImageQuery
                        alt="Wulkanizator Tychy"
                        imgName="wulkanizatorTychy"
                    />
                </div>

                <div className='fadeContainer' >
                    <ImageQuery
                        alt="Wymiana opon Tychy"
                        imgName="wymianaOponTychy"
                    />
                </div>
            </div>
            <div id="stage2">

                <div className="caption ">
                    <div className="indents">
                        <h2>Powierz nam <span>swoje auto</span></h2>
                            Wykwalifikowany personel sprawi, że szybko wrócisz na drogę.
                    </div>
                </div>
                <div className="caption ">
                    <div className="indents">
                        <h2>Wymienimy opony <span>w Twoim aucie </span></h2>
                            Chcesz założyć "zimówki"? Założymy i wyważymy je ekspresowo na
                            maszynach renomowanej firmy CEMB.
                    </div>
                </div>
                <div className="caption ">
                    <div className="indents">
                        <h2>Dobierzemy opony <span>do Twojego auta</span></h2>
                            Sprzedajemy opony marek: GoodYear, Dunlop, Fulda, Dębica, Michelin,
                            Kleber, Continental, Uniroyal.
                    </div>
                </div>
                <div className="caption ">
                    <div className="indents">
                        <h2>Przebita opona?<span> Naprawimy!</span></h2>
                            Wulkanizujemy opony na metodami na zimno i na gorąco.
                    </div>
                </div>
            </div>


            <ul id='pagination' className='pagination'>
                <li className='current'><span ></span></li>
                <li><span ></span></li>
                <li><span ></span></li>
                <li><span ></span></li>
            </ul>
        </div>
    )
}

export default SimpleSlider
